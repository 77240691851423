.Verify-Page-bg {
  background-image: url("./image/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.Verify-Page-rain {
  /* background-image: url("./image/rain.png");
  animation: rain 1s linear infinite; */
}

@keyframes rain {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 5% 100%;
  }
}

.Verify-Page-rain::before {
  /* content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./image/thunder.png");
  background-repeat: no-repeat;
  top: -4rem;
  transform: rotate(180deg);
  animation: thunder 5s linear infinite;
  opacity: 0.6; */
}

@keyframes thunder {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  21% {
    opacity: 0.7;
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  31% {
    opacity: 0.6;
  }
  35% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
